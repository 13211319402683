
:root{
   --main-color:#014a73;
   --color-dark:#1D2231;
   --text-grey:#8390A2;
}

* {
   padding: 0;
   margin: 0;
   box-sizing: border-box;
   list-style-type: none;
   text-decoration: none;
   font-family: 'Roboto', sans-serif;
}

dl, ol, ul{
  margin-bottom: 0 !important;
}

.mb-0{
   margin-bottom: 0rem !important;
}

.sidebar {
   width: 225px;
   position: fixed;
   top: 0;
   left: 0;
   height: 100%;
   background: var(--main-color);
   z-index: 100;
   transition: width 300ms;
   overflow-y: auto;
}

.sidebar-brand{
   display: flex;
   justify-content: center;
   align-items: center;
   height: 70px;
   padding: 1rem 0;
   color: #fff;
}

#nav-toggle:checked + .sidebar{
   width: 70px
}

#nav-toggle:checked + .sidebar li{
   padding-left: 1rem;
   text-align: center;
}

#nav-toggle:checked + .sidebar li a{
   padding-left: 1rem;
}

#nav-toggle:checked + .sidebar .sidebar-brand h5,
#nav-toggle:checked + .sidebar li a span:last-child{
   display: none;
}

.sidebar-menu {
   margin-top: 1rem;
}

.sidebar-menu li {
   width: 100%;
   margin-bottom: 1.5rem;
   padding-left: 1rem;
}

.sidebar-menu a{
   padding-left: 1rem;
   display: flex;
   align-items: center;
   color: #fff;
   font-size: 1.1rem;
}

.sidebar-menu a:hover{
  text-decoration: none;
  color: #fff;
}

.sidebar-menu a.active{
   background: #fafafa;
   padding-top: 0.5rem;
   padding-bottom: 0.5rem;
   color: var(--main-color);
   border-radius: 30px 0px 0px 30px;
}

.sidebar-menu a span:first-child{
   font-size: 1rem;
   padding-right: 0.8rem;
}

.sidebar-menu a span:last-child{
   font-size: 15px;
}

#nav-toggle:checked ~ .main-content{
   margin-left: 70px;
}

#nav-toggle:checked ~ .main-content header{
   width: calc(100% - 70px);
   left: 70px;
}

.main-content{
   transition: margin-left 300ms;
   margin-left: 225px;
}

header {
   background: #fff;
   display: flex !important;
   justify-content: space-between;
   align-items: center;
   padding: 0.1rem 1.5rem;
   box-shadow: 2px 2px 5px rgba(0,0,0,0.2);
   position: fixed;
   left: 225px;
   height: 60px;
   width: calc(100% - 225px);
   top: 0;
   z-index: 100;
   transition: margin-left 300ms;
}

#nav-toggle{
   display: none;
}

header h2{
   color: #222;
}

header label span{
   cursor: pointer;
   font-size: 1.4rem;
   padding-right: 1rem;
}

.header-user{
   position: relative;
}

.user-wrapper{
   display: flex;
   align-items: center;
   cursor: pointer;
   padding: 0.8rem;
}

.user-wrapper:hover{
   background: #fafafa;
}

.header-user:hover .dropdown-wrapper{
   display: block;
}

.user-wrapper img {
   border-radius: 50%;
   margin-right: 1rem;
}

.user-wrapper span{
  font-size: 12px;
   display: inline-block;
   color: var(--text-grey);
}

.dropdown-wrapper{
   display: none;
   position: absolute;
   background: #fff;
   border: 1px solid #e0e0e0;
   width: 100%;
   z-index: 1;
}

.dropdown-wrapper ul li{
   padding: 0.8rem;
   cursor: pointer;
   font-size: 13px;
   font-weight: 600;
}

.dropdown-wrapper ul li:hover{
   background: #fafafa;
}


main{
   margin-top: 75px;
   padding: 2rem 1.5rem;
   background: #fafafa;
   min-height: calc(100vh - 90px);
}

.cards{
   display: grid;
   grid-template-columns: repeat(4, 1fr);
   grid-gap: 2rem;
}

.card-single {
   display: flex;
   justify-content: space-between;
   background: #fff;
   padding: 1rem;
   border-radius: 2px;
   box-shadow: 0 1px 1px #e4e7ed;
   cursor: pointer;
   align-items: center;
}

a.card-single>div h1{
  color: var(--main-color);
}

a.card-single>div h3{
  color: var(--main-color);
}

a.card-single:hover, a.card-single>div:hover{
  text-decoration: none;
}


.card-single div:last-child span{
   font-size: 3rem;
   color: var(--main-color);
}
 
.card-single div:first-child span{
   color: var(--text-grey);
}


.block {
    background-color: #fff;
    box-shadow: 0 1px 1px #e4e7ed;
   cursor: pointer;
   border-radius: 5px;
}
 .block a {
   text-decoration: none;
   color: #000;
 }

 .block a:hover{
   text-decoration: none;
   /* color: #fff; */
   /* background-color: var(--main-color); */
   border-radius: 5px;
 }

.block > .active{
  background-color: var(--main-color);
  color: #fff;
  border-radius: 5px;
}

.block-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
    padding: 10px 20px;
    transition: opacity .2s ease-out;
    border-bottom: 1px dashed #e0e0e0;
}

.block-title {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 28px;
    margin: 0;
    font-size: 1.142857rem;
    font-weight: 400;
    line-height: 28px;
}

.block-options {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-left: 10px;
    min-height: 28px;
}

.block-content {
    transition: opacity .2s ease-out;
    margin: 0 auto;
    padding: 20px 20px;
    width: 100%;
    overflow-x: visible;
}

.block-content > table tbody  td{
  cursor: text;
  padding: 0.5rem;
  font-size: 14px;
}

.btn-floating-right{
  position: absolute;
  right: -10px;
  top: -10px;
  border-radius: 50% !important;
  width: 30px;
  height: 30px;
}

.item-category{
  display: inline-block;
  margin-right: 8px;
  border-radius: 5px;
  border: 1px solid rgb(229, 231, 233);
  background-color: rgb(255, 255, 255);
  -webkit-box-align: center;
  align-items: center;
  padding: 6px 12px;
  cursor: pointer;
  text-align: center;
}

.item-category > div{
  float: left;
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin-right: 8px;
}

.icon-category{
  background-color: transparent;
  display: inline-block;
  height: auto;
  margin: 0px auto;
  position: relative;
  text-align: center;
  width: 100%;
}

.icon-category > img{
  width: 100%;
  height: 100%;
}

.icon-category > img.fade{
  opacity: 1;
}

.icon-category span{
  float: left;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 26px;
  letter-spacing: normal;
  color: rgba(49, 53, 59, 0.68);
  vertical-align: middle;
}

.hr-text {
    max-width: 150px;
    margin: 16px auto;
    position: relative;
    height: 25px;
}

.hr-text span {
    background: #fff;
    color: #888;
    display: block;
    font-size: 13px;
    text-align: center;
    padding: 0 8px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.hr-text:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background: #ddd;
    top: 10px;
    z-index: 0;
}

.nav-tabs a{
  color: rgb(1, 74, 115);
  font-weight: bold;
  text-align: center;
}

.item-2 .nav-tabs .nav-item{
  width: 50%;
}

.item-3 .nav-tabs .nav-item{
  width: 33.3%;
}

.item-4 .nav-tabs .nav-item{
  width: 25%;
}

.radius{
  border-radius: 50%;
}


.heading-title {
  font-size: 20px!important;
  line-height: 10px!important;
  margin-bottom: 10px;
  font-weight: 600;
  color: #35405a;
}

.heading-title::before {
  content: "";
  display: inline-block;
  width: 5px;
  height: 15px;
  border-radius: 2.5px;
  background-color: rgb(1, 74, 115);
  margin-right: 10px;
}

.ticket-card {
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 4px;
  -webkit-box-shadow: 0 4px 8px 0 rgb(53 64 90 / 8%);
  box-shadow: 0 4px 8px 0 rgb(53 64 90 / 8%);
  border: .7px solid #dee2ee;
  background-color: #fff;
  margin-bottom: 13px;
  padding: 15px;
}

.ticket-card-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 8px;
}

button.btn-ticket-card {
  border: none;
  background: none;
  padding: 0;
  color: #0064d2;
}

.ticket-desc span {
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #58627a;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.ticket-footer {
  border-top: 1px dashed #dee2ee;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 15px;
  flex-direction: column;
}
.ticket-price {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.ticket-price h5 {
  font-size: 15px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #8a93a7;
}

.ticket-price span.price {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #35405a;
}

.counter {
  width: 97px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 20px;
}

.counter-btn {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: #fff;
  border: 1px solid #dee2ee;
  position: relative;
  cursor: pointer;
}

.tab-content{
  border-bottom: 1px solid #dee2ee;
  border-right: 1px solid #dee2ee;
  border-left: 1px solid #dee2ee;
}

.summary p {
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #58627a;
}

.event-box {
    position: relative;
    padding: 15px;
}

.limiter {
    width: 100%;
    margin: 0 auto;
}

.container-login {
    width: 100%;
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background: var(--main-color);
    background: -webkit-linear-gradient(
-135deg
,var(--main-color),#4158d0);
    background: -o-linear-gradient(-135deg,var(--main-color),#4158d0);
    background: -moz-linear-gradient(-135deg,var(--main-color),#4158d0);
    background: linear-gradient(
-135deg
,var(--main-color),#4158d0);
}

.wrap-login {
    width: 1000px;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 80px;
    align-items: center;
}

.login-pic {
    width: 316px;
}

.login-pic img {
    max-width: 100%;
}

.login-form {
    width: 350px;
}



.loading{
    border-radius: 10px;
    background-color:#f2f2f2;
    position: relative;
    overflow: hidden;
}

.loading-card-image{
    height: 250px;
    background-color: #ddd;
}

.loading-category-title{
    height: 50px;
    background-color: #ddd;
}

.loading-card-content{
    padding: 10px;
}

.loading-title{
    width: 100%;
    height: 15px;
    border-radius: 10%;
    background-color: #ddd;
}

.loading-title-small{
    width: 50%;
    height: 10px;
    border-radius: 10%;
    background-color: #ddd;
}

.loading-category-list{
    padding: 10px;
}

.loading-animation-wrapper{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: loading 1.3s infinite;
}

.loading-animation{
    width: 75%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.3);
    transform: skewX(-20deg);
    box-shadow: 0 0 30px 30px rgba(255, 255, 255, 0.05);
}

.dash{
   margin: 20px 0;
   border-top: 1px dashed #dee2ee;
}

img.card-event-image{
  width: 100%;
  aspect-ratio: 2/3;
  object-fit: cover;
}

a.text-link{
   color: #0064d2;
   font-weight: 700;
}

a.text-link{
   background-color: none !important;
}

.divider{
   padding-bottom: 10px;
   margin-bottom: 10px;
   border-bottom: 1px solid #e0e0e0;
}

.divider:last-child{
   border-bottom: none;
   margin-bottom: 0;
}

.text-small{
   font-size: 14px;
}

@keyframes loading {
    0% { transform: translateX(-150%);}
    50% {transform: translateX(-60%);}
    100% { transform: translateX(150%);}

}

.table-small td, .table-small th{
   padding: .30rem !important;
   vertical-align:middle !important;
   font-size: 15px;
}


@media only screen and (max-width:1200px) {
   .sidebar{
      width: 70px
   }

   .sidebar li{
      padding-left: 1rem;
      text-align: center;
   }

   .sidebar li a{
      padding-left: 0rem;
   }

   .sidebar .sidebar-brand h5,
   .sidebar li a span:last-child{
      display: none;
   }

   .sidebar-menu a span:first-child{
      padding-left: 1rem;
   }

   .main-content{
      margin-left: 70px;
   }

   .main-content header{
      width: calc(100% - 70px);
      left: 70px;
   }

   #nav-toggle:checked + .sidebar{
      left: 0 !important;
      z-index: 100;
      width: 225px;
   }

   #nav-toggle:checked + .sidebar li{
      padding-left: 2rem;
      text-align: left;
   }

   #nav-toggle:checked + .sidebar li a{
      padding-left: 1rem;
   }

   #nav-toggle:checked + .sidebar-brand h3,
   #nav-toggle:checked + .sidebar li a span:last-child{
      display: inline;
   }

   #nav-toggle:checked + .main-content{
      margin-left: 0rem !important;
   }

}

@media only screen and (max-width:960px) {
   .cards{
      grid-template-columns: repeat(3, 1fr);
   }

   #nav-toggle:checked + .sidebar{
      left: 0 !important;
      z-index: 100;
      width: 225px;
   }

   #nav-toggle:checked + .sidebar li{
      padding-left: 2rem;
      text-align: left;
   }

   #nav-toggle:checked + .sidebar li a{
      padding-left: 1rem;
   }

   #nav-toggle:checked + .sidebar-brand h3,
   #nav-toggle:checked + .sidebar li a span:last-child{
      display: inline;
   }

   #nav-toggle:checked + .main-content{
      margin-left: 0rem !important;
   }
}

@media only screen and (max-width:768px) {
   .cards{
      grid-template-columns: repeat(2, 1fr);
   }

   .sidebar{
      left: -100% !important;
   }

   header h4{
      display: flex;
      align-items: center;
   }

   header h4 label{
       background: var(--main-color);
       padding-right: 0rem;
       margin-right: 1rem;
       height: 40px;
       width: 40px;
       border-radius: 50%;
       color: #fff;
       display: flex;
       align-items: center;
       justify-content: center;
   }

   header h4 span {
      text-align: center;
      padding-right: 0;
   }

   header h4 {
      font-size: 1.1rem;
   }

   .main-content{
      width: 100%;
      margin-left: 0rem;
   }

   header{
      width: 100% !important;
      left: 0 !important;
   }

    .sidebar-menu a span:first-child{
      padding-left: 0rem;
   }


   #nav-toggle:checked + .sidebar{
      left: 0 !important;
      z-index: 100;
      width: 225px;
   }

   #nav-toggle:checked + .sidebar li{
      padding-left: 2rem;
      text-align: left;
   }

   #nav-toggle:checked + .sidebar li a{
      justify-content: start;
      padding-left: 1rem;
   }

   #nav-toggle:checked + .sidebar-brand h3,
   #nav-toggle:checked + li a span:last-child{
      display: inline;
   }

   #nav-toggle:checked + .main-content{
      margin-left: 0rem !important;
   }
}

@media only screen and (max-width:560px) {
   .cards{
      grid-template-columns: repeat(1, 1fr);
   }
}


